import * as React from 'react'

import JobSidebar from '../components/jobSidebar'
import SEO from '../components/seo/seo'
import HomeownerDashboard from '../components/dashboards/homeowner'

const HomesPage = () => (
	<JobSidebar>
		<SEO title="Homes" />
		<HomeownerDashboard />
	</JobSidebar>
)

export default HomesPage
