import React from 'react'
import styled from 'styled-components'
import {
	HomeownerDashboardFragFragment,
	PropertyStatus
} from '../../apollo/generated'
import { Typography, Button, List } from '@material-ui/core'
import PropertyCardJobItem from './PropertyCardJobItem'
import { UPDATE_SELECTED_PROPERTY_ID } from '../../apollo/mutations'
import { useMutation } from '@apollo/react-hooks'
import { ButtonProps } from '@material-ui/core/Button'
import { navigate } from 'gatsby'

interface Props {
	property: HomeownerDashboardFragFragment['properties'][0]
}

const PropertyCard = ({ property }: Props) => {
	const [updateSelectedId] = useMutation(UPDATE_SELECTED_PROPERTY_ID)
	const setSelectedPropertyId = (nav?: string) => () => {
		updateSelectedId({ variables: { id: property.id } })
		nav && navigate(nav)
	}

	let internal
	if (property.ready === PropertyStatus.Denied || property.ready === PropertyStatus.Canceled) {
		internal = (
			<Typography align="center" style={{ marginTop: '30%' }}>
				Property Unavailable
			</Typography>
		)
	} else if (property.ready === PropertyStatus.PaymentFailed) {
		internal = (
			<Typography align="center" style={{ marginTop: '30%' }}>
				Payment Failed
			</Typography>
		)
	} else if (property.ready !== PropertyStatus.Ready) {
		internal = (
			<Typography align="center" style={{ marginTop: '30%' }}>
				3D Models Are Currently Processing
			</Typography>
		)
	} else {
		const jobsBody = property.jobs.length ? (
			<>
				<List dense>
					{property.jobs.map(j => (
						<PropertyCardJobItem job={j} key={j.id} />
					))}
				</List>
				<ViewMore
					visible={property.jobs.length >= 5}
					onClick={setSelectedPropertyId('/jobs')}
				/>
			</>
		) : (
			<Typography style={{ marginLeft: 8 }}>
				<em>No Ongoing Jobs</em>
			</Typography>
		)
		internal = <ReadyPropertyInner>{jobsBody}</ReadyPropertyInner>
	}

	return (
		<Outer>
			<Typography
				variant="h5"
				style={{ fontSize: 14, marginLeft: 16 }}
				gutterBottom
			>
				{property.houseNumber} {property.streetName}
			</Typography>
			<ImageCon>
				<img src={property.designCenterImageUrl} />
				<ButtonRow>
					<Button
						onClick={setSelectedPropertyId('/jobs/new')}
						size="small"
					>
						Create Job
					</Button>
					<Button
						onClick={setSelectedPropertyId('/my-home')}
						size="small"
					>
						Design Center
					</Button>
				</ButtonRow>
			</ImageCon>
			{internal}
		</Outer>
	)
}

export default React.memo(PropertyCard)

const ViewMore = ({
	visible,
	count,
	...rest
}: { visible?: boolean; count?: number } & ButtonProps) => {
	if (!visible || count === 0) {
		return null
	}
	return (
		<ViewMoreButton {...rest}>
			View {count ? `${count} ` : ''}More
		</ViewMoreButton>
	)
}

const ViewMoreButton = styled(Button).attrs({
	size: 'small',
	variant: 'text'
})`
	align-self: flex-end;
	font-size: 12px;
	text-transform: none;
	margin: -12px 6px;
` as typeof Button

const Outer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 440px;
	background-color: white;
	color: #333;
	margin: 24px 12px;
	padding: 16px 0;
	border-radius: 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
	height: 572px;
`

const ImageCon = styled.div`
	position: relative;
	margin: 8px 16px 16px 16px;
	> img {
		height: 180px;
		width: 100%;
		max-height: 300px;
		object-fit: cover;
		border-radius: 2px;
		background-color: rgb(150, 150, 150);
		margin-bottom: 0;
	}
`

const ReadyPropertyInner = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: stretch;

	li {
		margin-bottom: 0 !important;
	}
`

const ButtonRow = styled.div`
	&& {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		position: absolute;
		bottom: -8px;
		right: 8px;
		left: 8px;

		> * {
			font-size: 12px;
			padding: 4px 12px;
			text-transform: inherit;
			letter-spacing: 0.3px;
			box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.5);
			border: 1px solid rgba(0, 0, 100, 0.3);
		}
	}
`
