import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { useHomeownerDashboardQuery } from '../../apollo/generated'
import Loading from '../ui/Loading'
import PropertyCard from './PropertyCard'

const HomeownerDashboard = () => {
	const { data, loading, error } = useHomeownerDashboardQuery()

	if (error) {
		return <Typography>{error.message}</Typography>
	} else if (loading || !data || !data.homeowner) {
		return <Loading />
	}

	return (
		<Container>
			{data.homeowner.properties.map(p => (
				<PropertyCard key={p.id} property={p} />
			))}
		</Container>
	)
}

export default HomeownerDashboard

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-evenly;
	flex-wrap: wrap;
`
